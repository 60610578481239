import React from "react";
import Layout from "../components/layout";
import Landing from "../components/landing/landing";
import SEO from "../components/seo";

const Index = () => {
  return (
    <Layout>
      <SEO title="Home" article={false} pathname={`/`} />

      <Landing />
    </Layout>
  );
};

export default Index;
